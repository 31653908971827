module.exports = [{
      plugin: require('/Users/robiet/Development/ValorWebSite/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://valordigital.com.au"},
    },{
      plugin: require('/Users/robiet/Development/ValorWebSite/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142838117-1"},
    },{
      plugin: require('/Users/robiet/Development/ValorWebSite/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T4VHV4Q","includeInDevelopment":false,"gtmAuth":"YOUR_GOOGLE_TAGMANAGER_ENVIROMENT_AUTH_STRING","gtmPreview":"YOUR_GOOGLE_TAGMANAGER_ENVIROMENT_PREVIEW_NAME","dataLayerName":"YOUR_DATA_LAYER_NAME"},
    },{
      plugin: require('/Users/robiet/Development/ValorWebSite/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
