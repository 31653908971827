// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kbs-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/kbs.js" /* webpackChunkName: "component---src-pages-kbs-js" */),
  "component---src-pages-kbs-cms-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/kbs/cms.js" /* webpackChunkName: "component---src-pages-kbs-cms-js" */),
  "component---src-pages-services-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-digitalmarketing-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/services/digitalmarketing.js" /* webpackChunkName: "component---src-pages-services-digitalmarketing-js" */),
  "component---src-pages-solutions-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-solutions-dynamics-365-js": () => import("/Users/robiet/Development/ValorWebSite/src/pages/solutions/dynamics365.js" /* webpackChunkName: "component---src-pages-solutions-dynamics-365-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/robiet/Development/ValorWebSite/.cache/data.json")

